export const nearbyCompanies = {
  order: 7,
  name: "Find nearby companies",
  keywords: "map company own lend broker",
  subcategory: "The map",
  markdown: `# Find nearby companies

  Identifying the players in a market is one of the most important steps you can take toward understanding the dynamics of that market. Tower Hunt's {% inlineRouterLink %}Big Map{% /inlineRouterLink %} can help with this.

  {% callout type="tip" %}
  **Tip:** To link companies and contacts in your market, be sure to check out {% inlineRouterLink %}understanding the capital stack{% /inlineRouterLink %} and {% inlineRouterLink %}types of company roles in Tower Hunt{% /inlineRouterLink %}.
  {% /callout %}

  ## Display nearby companies

  To see which companies are present in a market, visit the {% inlineRouterLink articleId="the-role-of-the-analyze-tab" %}Analyze tab{% /inlineRouterLink %} and turn on the "Companies" filter. Whenever you {% inlineRouterLink articleId="map-movement" %}move the map{% /inlineRouterLink %}, the nearby companies will refresh.

  ## Understand colors, icons, and borders

  Below each company, you will see one or more squares. Each square represents an involvement the company has with a property that is visible on the map.

  ### Appearance

  Use the table below to better understand the visual elements on each square.

  | **Visual** | **Element description** | **Represents** |
  |---|---|---|
  | {% involvementSquare squareClasses="border-gray-400 shadow-gray-400/50" state="" /%} | Gray border | Unlockable |
  | {% involvementSquare squareClasses="border-yellow-400 shadow-yellow-400/50" state="" /%} | Yellow border | Owner |
  | {% involvementSquare squareClasses="border-blue-600 shadow-blue-600/50" state="" /%} | Blue border | Lender |
  | {% involvementSquare squareClasses="border-teal-400 shadow-teal-400/50" state="" /%} | Teal border | Advisor |
  | {% involvementSquare squareClasses="border-dashed border-indigo-300" state="" /%} | Dashed border | Prospect |
  | {% involvementSquare squareClasses="border-gray-400 shadow-gray-400/50" state="live" /%} | Red circle | Live |
  | {% involvementSquare squareClasses="border-gray-400 shadow-gray-400/50" state="underContract" /%} | Orange triangle | Under contract |
  | {% involvementSquare squareClasses="border-gray-400 shadow-gray-400/50" state="withdrawn" /%} | Gray skull | Withdrawn |

  {% callout type="tip" marginStyles="mb-4" %}
  **Tip:** A square may combine more than one of the elements below. For example, an advisor on a live investment would display as a teal border with a red circle.
  {% /callout %}

  {% callout type="note" %}
  **Note:** If you have access to a {% inlineRouterLink %}photo{% /inlineRouterLink %} datapoint for the corresponding property, it will replace the Tower Hunt logo inside the square.
  {% /callout %}

  ### Interactions

  Interacting with a square provides more context.

  | **Interaction** | **What happens** |
  |---|---|
  | Point at square | Pop-up appears. Corresponding map marker pulses orange. |
  | Click square | Investments open in {% inlineRouterLink %}Deal Builder{% /inlineRouterLink %}. Property-level involvements do nothing. |

  The pop-up summarizes the involvement type, company role (if known), and corresponding property.

  ![Involvement square pop-up screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
};
